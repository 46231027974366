.App {
  background-color: #61dafb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-grey{
  background-color: #dde7f8;
}

.bg-green{
  background-color: rgb(175, 238, 238)
}

.project{
  padding: 15px 5px;
  border:solid darkcyan;
  border-width:4px 4px;
}

.about{
  padding: 15px 5px;
  border:solid darkcyan;
  border-width:4px 4px;
}

/*Navigation Bar*/
ul.navigation
{
    /*sticky*/
    position: sticky;
    top:0;
    background-color: darkcyan;
    list-style-type: none;
    padding: 0%;
    overflow: hidden;
    border: solid white 1px;
    z-index: 11;
}

ul.navigation li
{ 
    text-align: center;
    position: relative;
    float: left;
    padding: 0px;
    margin: 0px;
    width: 120px;
    height: 100%;
    border: solid white 0px;
    border-right-width: 1px;
    display: block;
    color: white;
}

ul.navigation li a, ul.navigation li a:link,ul.navigation li a:hover,ul.navigation li a:active
{
    color:white;
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding: 5px 0px;
    margin: 0px;
    
}

ul.navigation li a:hover, a:active
{ 
    background-color: rgb(89, 116, 189);
}

li.currentPage
{
    background-color: rgb(2, 95, 95)
}